<template>
    <div class="stateInventory responsive-menu">
      <div class="responsive-menu__child">
        <PageHeader
          title="Customer Credit Memo Status"
          :links="[
            { name: 'home', label: 'Home' },
            { name: 'reports', label: 'Reports' },
            { name: 'customerReport', label: 'Customer' },
            {
              name: 'customerCreditMemoBalance',
              label: 'Customer Credit Memo Status',
            },
          ]"
          @export="exportToCsv"
          :disableExport="!reportData"
        />
        <br class="no-print" />
        <ReportFilter
          class="no-print"
          :filterByMemoType="true"
          @submit-filter="performGetReport"
          :isLoading="customerTransactions"
        />
        <div class="report-container">
          <!-- This could be a component -->
          <div class="report-body" v-if="reportData">
            <table id="table-report">
              <thead>
                <tr class="report-body__row report-body__row--header"></tr>
                <tr>
                    <th align="left">Date</th>
                    <th align="left">Type</th>
                    <th align="left">Cash Reason</th>
                    <th align="right">Balance</th>
                    <th align="right">Amount Used</th>
                    <th align="right">Total</th>
                    <th align="left">Notes</th>
                    <th align="left">Customer Name</th>
                    <th align="left">Customer Last Name</th>
                    <th align="left">Customer Email</th>
                    <th align="left">Supplier Name</th>
                    <th align="left">Supplier</th>
                    <th align="left">Store</th>
                    <th align="left">Id</th>
                </tr>
              </thead>

              <tbody>
                <tr class="report-body__row"></tr>
                <tr v-for="(record, index) in reportData.records" :key="index">
                    <td>{{ record.date }}</td>
                    <td>{{ record.memoType }}</td>
                    <td>{{ record.memoTypeCashReason }}</td>
                    <td align="right"> {{ Utilities.formatPrice(record.memoBalance) }}</td>
                    <td align="right"> {{ Utilities.formatPrice(record.memoAmountUsed) }}</td>
                    <td align="right"> {{ Utilities.formatPrice(record.memoTotal) }}</td>
                    <td>{{ record.notes }}</td>
                    <td>{{ record.customerName }}</td>
                    <td>{{ record.customerLastName }}</td>
                    <td>{{ record.customerEmail }}</td>
                    <td>{{ record.supplierName }}</td>
                    <td>{{ record.supplier }}</td>
                    <td>{{ record.storeName }}</td>
                    <td>{{ record.id }}</td>
                </tr>
              </tbody>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
              <tr>
                <td colspan="3" align="left">Totals</td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.memoBalance) }}</td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.memoAmountUsed) }}</td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.memoTotal) }}</td>
              </tr>
              <tr class="skip-export"></tr>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import PageHeader from "@/components/Header/Header.vue";
  import ReportHelper from "@/lib/report/index.js";
  import { mapActions, mapGetters } from "vuex";
  import ReportFilter from "@/components/ReportFilter/ReportFilter";
  import Utilities from "@/lib/Utilities";

  export default {
    data() {
      return {
        reportData: undefined,
        Utilities,
        reportType: undefined,
        selectedPeriod: undefined,
        selectedReportFor: undefined,
        selectedReportSaleType: undefined,
        filterDropdown: undefined,
      };
    },
    components: {
      PageHeader,
      ReportFilter,
    },
    created: function () {
      this.setPageTitle("Customer Credit Memo Status");
    },
    mounted: function () {
      const today = new Date();
      this.selectedPeriod = {
        from: new Date(new Date().setDate(today.getDate() - 7)),
        to: today,
      };
    },
    computed: {
      ...mapGetters({
        loadingStatus: "reports/loadingStatus",
        customerTransactions: "reports/customerTransactions",
      }),
    },
    methods: {
      ...mapActions({
        getSalesTransactions: "reports/getCustomerTransactions",
      }),
      performGetReport(filter) {
        let reportType = "customerCreditMemoBalance";
        this.getSalesTransactions({
          reportType,
          ...filter,
          positiveCallback: (data) => {
            this.reportData = data;
          },
        });
      },
      exportToCsv() {
        var table = document.getElementById("table-report");
        ReportHelper.exportToExcel(table, "customerCreditMemoBalance");
      },
    },
  };
  </script>

  <style lang="scss">
  @import "./_customerCreditMemoBalance.scss";
