import HttpService from "@/services/HttpService";
import LocalStorageManager from "@/lib/LocalStorageManager";
import router from "@/router";
import Apis from "@/services/Apis";
import Vue from 'vue'

const findProductInOrder = (order, batchIdOrId) => {
  for (const product of order.products) {
    if (product.items.find(i => i.batchId == batchIdOrId || i._id == batchIdOrId)) {
      return product
    }
  }
  return undefined
}

// filter portion to exclude ongoing online store orders
const excludeOnlineStoreInProgressOrders = {
  $or: [
    { source: "pos" },
    { source: "online-store", isPreorder: true },
    { source: "online-store", isPending: true },
    { source: "kiosk", isPreorder: true },
    { source: "kiosk", isPending: true },
  ],
};

const state = {
  orders: [],
  posOrder: {},
  order: {},
  loadingStatus: {
    saveOrder: false,
    getOrders: false,
    getOrderById: false,
    archiveOrder: false,
    reverseOrder: false,
    addRemoveItemToOrder: false,
    archiveAllPayments: false,
    processOrder: false,
    savePayment: false,
    reimburseCanpayPayment: false,
    createCreditMemoFromPayment: false,
    executeCanpayPayment: false,
    getAllOpenOrders: false,
    executeDebitPayment: false,
    removeClutchDiscountFromOrder: false
  },
  orderTypeSelectionPopUp: {
    isOpen: false,
  },
  orderPopUp: {
    isOpen: false,
  },
  selectedFilter: undefined,
  selectedPreFilters: undefined,
  sort: { postedToJournalAt: -1 },
  pagination: { skip: 0, limit: 20 },
  filterButtons: [
    {
      isSelected: true,
      _id: 0,
      label: "All",
      name: "all",
      showAll: false,
      filter: excludeOnlineStoreInProgressOrders,
    },
    {
      isSelected: false,
      _id: 1,
      label: "Online Store",
      name: "online-store",
      filter: {
        source: "online-store",
        $or: [{ isPreorder: true }, { isPending: true }], // show only submitted online store orders, not in progress orders
      },
    },
    {
      isSelected: false,
      _id: 2,
      label: "POS",
      name: "pos",
      filter: {
        source: "pos",
      },
    },
    {
      isSelected: false,
      _id: 3,
      label: "Pending",
      name: "pending",
      filter: {
        isPending: true,
        isArchived: false,
        ...excludeOnlineStoreInProgressOrders,
      },
    },
    {
      isSelected: false,
      _id: 4,
      label: "Kiosk",
      name: "kiosk",
      filter: {
        source: "kiosk",
        $or: [{ isPreorder: true }, { isPending: true }], // show only submitted kiosk orders, not in progress orders
      },
    },
    {
      isSelected: false,
      _id: 5,
      label: "Open Orders",
      name: "open-orders",
      filter: {
        isArchived: false,
        isPaid: false,
        isPostedToJournal: false,
        isVoided: false,
        ...excludeOnlineStoreInProgressOrders,
      },
    },
  ],
  initialFilter: undefined,
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setOrders: (state, payload) => {
    state.orders = payload;
  },
  setOrder: (state, payload) => {
    if (payload) {
      state.order = payload;
    }
  },
  setPosOrder: (state, payload) => {
    if (payload) {
      state.posOrder = payload;
      LocalStorageManager.setLocalStorageKey("orderId", payload._id);
    } else {
      LocalStorageManager.deleteLocalStorageKey("orderId");
      state.posOrder = {};
    }
  },
  setOrderTypeSelectionPopUp: (state, payload) => {
    state.orderTypeSelectionPopUp = payload;
  },
  cancelOrder: (state, payload) => {
    state.posOrder = {};
    LocalStorageManager.deleteLocalStorageKey("orderId");
  },
  assignCustomer: (state, payload) => {
    state.posOrder.customer = payload.customer;
  },
  setOrderPopUp: (state, payload) => {
    state.orderPopUp = payload;
  },
  setPayment: (state, payload) => {
    state.posOrder.payment = payload.type;
    state.posOrder.isCheckingOut = true;
    if (payload.type == "pre-order") {
      state.posOrder.isPreordering = true;
    }
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateFilterButtons: (state, payload) => {
    state.filterButtons = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
  scanItem: (state, payload) => {
    const product = payload.product
    if (product) {
      if (!product.scannedQuantity) {
        Vue.set(product, 'scannedQuantity', 1)
      } else {
        product.scannedQuantity++
      }
    }
  },
  resetScanCounters: (state) => {
    for (const product of state.posOrder.products) {
      Vue.set(product, 'scannedQuantity', 0)
    }
  },
};

const getters = {
  orders: (state) => state.orders,
  order: (state) => state.order,
  posOrder: (state) => state.posOrder,
  loadingStatus: (state) => state.loadingStatus,
  orderTypeSelectionPopUp: (state) => state.orderTypeSelectionPopUp,
  selectedFilter: (state) => state.selectedFilter,
  selectedPreFilters: (state) => state.selectedPreFilters,
  orderPopUp: (state) => state.orderPopUp,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  filterButtons: (state) => state.filterButtons,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveOrder: ({ commit, state, rootGetters }, payload) => {
    // Prevent multiple saving while order is loading
    if (state.loadingStatus.saveOrder) {
      return;
    }
    payload.order.workstation = rootGetters["posModule/selectedWorkstation"];
    payload.order._entity = "order";
    commit("setLoadingStatus", { api: "saveOrder", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveOrder,
      payload.order,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveOrder", status: false });
        if (response) {
          const order = response.data.body;
          //commit("setOrder", order);
          commit("setPosOrder", order);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          if (payload && payload.negativeCallback) {
            payload.negativeCallback(response);
          }
        }
      }
    );
  },
  updateOrder: ({ commit, state, rootGetters }, payload) => {
    // Prevent multiple saving while order is loading
    if (state.loadingStatus.updateOrder) {
      return;
    }
    payload.order.workstation = rootGetters["posModule/selectedWorkstation"];
    payload.order._entity = "order";
    commit("setLoadingStatus", { api: "updateOrder", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.updateOrder,
      payload.order,
      function (response, error) {
        commit("setLoadingStatus", { api: "updateOrder", status: false });
        if (response) {
          const order = response.data.body;
          commit("setPosOrder", order);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          if (payload && payload.negativeCallback) {
            payload.negativeCallback(response);
          }
        }
      }
    );
  },
  setLoyaltyCustomer: ({ commit, state, rootGetters }, payload) => {
    commit("setLoadingStatus", { api: "setLoyaltyCustomer", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.order.setLoyaltyCustomer,
      {
        _entity: "order", // sent for consistency check
        updatedAt: payload.updatedAt, // order updated at sent for consistency check
        _id: payload.orderId, // sent for consistency check
        orderId: payload.orderId,
        customerId: payload.customerId,
        cardNumber: payload.cardNumber
      },
      function (response, error) {
        commit("setLoadingStatus", { api: "setLoyaltyCustomer", status: false });
        if (response) {
          const order = response.data.body;
          commit("setPosOrder", order);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          if (payload && payload.negativeCallback) {
            payload.negativeCallback(response);
          }
        }
      }
    );
  },
  processOrder: ({ commit, state, rootGetters }, payload) => {
    // Prevent multiple saving while order is loading
    if (state.loadingStatus.processOrder) {
      return;
    }
    state.order.workstation = rootGetters["posModule/selectedWorkstation"];
    commit("setLoadingStatus", { api: "processOrder", status: true });
    const orderToProcess = payload.order ? payload.order : state.order;
    orderToProcess._entity = "order";
    HttpService.post(
      Apis.backendBaseUrl + Apis.processOrder,
      orderToProcess,
      function (response, error) {
        commit("setLoadingStatus", { api: "processOrder", status: false });
        if (response) {
          const order = response.data.body;
          if (payload.isPos) {
            commit("setPosOrder", order);
          } else {
            commit("setOrder", order);
          }
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          if (payload && payload.negativeCallback) {
            payload.negativeCallback(response);
          }
        }
      }
    );
  },
  setPosOrder: ({ commit, state, rootGetters }, payload) => {
    commit("setPosOrder", payload);
  },
  postOrder: ({ commit, state, rootGetters }, payload) => {
    // Prevent multiple saving while order is loading
    if (state.loadingStatus.saveOrder) {
      return;
    }
    payload.order.workstation = rootGetters["posModule/selectedWorkstation"];
    payload.order._entity = "order";
    commit("setLoadingStatus", { api: "saveOrder", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.postOrder,
      payload.order,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveOrder", status: false });
        if (response) {
          const order = response.data;
          commit("setPosOrder", order);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(order);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            {
              text: error.message,
              type: error.errorCode == "12345" ? "state-error" : "error",
              errorType: error.errorCode == 1004 ? "no-workstation" : undefined,
            },
            { root: true }
          );
          if (payload && payload.negativeCallback) {
            payload.negativeCallback(response);
          }
        }
      }
    );
  },
  archiveOrder: ({ commit, state, rootGetters }, payload) => {
    commit("setLoadingStatus", { api: "archiveOrder", status: true });
    const workstation = rootGetters["posModule/selectedWorkstation"];
    HttpService.post(
      Apis.backendBaseUrl + Apis.archiveOrder,
      { mode: "archive", id: state.posOrder._id, _entity: 'order', updatedAt: state.posOrder.updatedAt, workstation },
      function (response, error) {
        commit("setLoadingStatus", { api: "archiveOrder", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Archived", type: "success" },
            { root: true }
          );
          const order = response.data.body;
          commit("setPosOrder", order);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(order);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            {
              text: error.message,
              type: "error",
              errorType: error.errorCode == 1004 ? "no-workstation" : undefined,
            },
            { root: true }
          );
        }
      }
    );
  },
  archiveOpenOrder: ({ commit, rootGetters }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingStatus", { api: "archiveOrder", status: true });
      const workstation = rootGetters["posModule/selectedWorkstation"];
      HttpService.post(
        Apis.backendBaseUrl + Apis.archiveOrder,
        { mode: "archive", id: payload._id, _entity: 'order', updatedAt: payload.updatedAt, workstation },
        function (response, error) {
          commit("setLoadingStatus", { api: "archiveOrder", status: false });
          if (response) {
            commit(
              "setMessage",
              { text: "Archiving", type: "success" },
              { root: true }
            );
            const order = response.data.body;
            if (payload && payload.positiveCallback) {
              payload.positiveCallback(order);
            }
            resolve(order);
          } else {
            console.log(error);
            reject(error);
            if (error.errorCode == 1004) {
              commit(
                "setMessage",
                {
                  text: error.message,
                  type: "error",
                  errorType: "no-workstation"
                },
                { root: true }
              );
            }
          }
        }
      );
    });
  },
  archiveOpenOrderAsync: async ({ commit, rootGetters }, payload) => {
    commit("setLoadingStatus", { api: "archiveOrder", status: true });
    const workstation = rootGetters["posModule/selectedWorkstation"];
    try {
      const res = await HttpService.postAsync(
        Apis.backendBaseUrl + Apis.archiveOrder,
        { mode: "archive", id: payload._id, _entity: 'order', updatedAt: payload.updatedAt, workstation },
      );
      commit("setLoadingStatus", { api: "archiveOrder", status: false });
      return res;
    } catch (error) {
      commit("setLoadingStatus", { api: "archiveOrder", status: false });
      throw new Error(error?.response?.data?.message || "Error archiving open orders");
    }
  },

  deleteCurrentOrder: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "deleteOrder", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteOrder,
      { id: state.posOrder._id },
      function (response, error) {
        commit("setLoadingStatus", { api: "deleteOrder", status: false });
        if (error) {
          console.log(error);
        }
      }
    );
    console.log(`deleting: ${state.posOrder._id}`);
  },
  savePayment: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "savePayment", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.savePayment,
      { order: state.posOrder, payment: payload.payment },
      function (response, error) {
        commit("setLoadingStatus", { api: "savePayment", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Payment saved", type: "success" },
            { root: true }
          );
          const order = response.data.body;
          commit("setPosOrder", order);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(order);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  archivePayment: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "archivePayment", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.archivePayment,
      { order: state.posOrder, payment: payload.payment },
      function (response, error) {
        commit("setLoadingStatus", { api: "archivePayment", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Payment archived", type: "success" },
            { root: true }
          );
          const order = response.data.body;
          commit("setPosOrder", order);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(order);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  reimburseCanpayPayment: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "reimburseCanpayPayment", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.pos.reimburseCanpayPayment,
      { order: state.posOrder, payment: payload.payment },
      function (response, error) {
        commit("setLoadingStatus", {
          api: "reimburseCanpayPayment",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Payment reimbursed", type: "success" },
            { root: true }
          );
          const order = response.data.body;
          commit("setPosOrder", order);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(order);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  createCreditMemoFromPayment: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "createCreditMemoFromPayment",
      status: true,
    });
    HttpService.post(
      Apis.backendBaseUrl + Apis.pos.createCreditMemoFromPayment,
      {
        order: state.posOrder,
        payment: payload.payment,
        creditmemo: payload.creditmemo,
      },
      function (response, error) {
        commit("setLoadingStatus", {
          api: "createCreditMemoFromPayment",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Credit memo created", type: "success" },
            { root: true }
          );
          const order = response.data.body;
          commit("setPosOrder", order);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(order);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  executeDebitPayment: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "executeDebitPayment", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.pos.executeDebitPayment,
      { order: state.posOrder, payment: payload.payment },
      function (response, error) {
        commit("setLoadingStatus", {
          api: "executeDebitPayment",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Payment", type: "success" },
            { root: true }
          );
          const order = response.data.body;
          commit("setPosOrder", order);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(order);
          }
        } else {
          console.log(error);
          if (error.orderUpdated) {
            commit("setPosOrder", error.orderUpdated);
          }
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  executeOverrideDebitPayment: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "executeOverrideDebitPayment", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.pos.executeOverrideDebitPayment,
      { order: state.posOrder, payment: payload.body.payment, employee: payload.body.employee },
      function (response, error) {
        commit("setLoadingStatus", {
          api: "executeOverrideDebitPayment",
          status: false,
        });

        if (response) {
          commit(
            "setMessage",
            { text: "Payment", type: "success" },
            { root: true }
          );
          const order = response.data.body;
          commit("setPosOrder", order);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(order);
          }
        } else {
          console.log(error);
          if (error.orderUpdated) {
            commit("setPosOrder", error.orderUpdated);
          }
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  executeCanpayPayment: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "executeCanpayPayment", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.pos.executeCanpayPayment,
      { order: state.posOrder, payment: payload.payment },
      function (response, error) {
        commit("setLoadingStatus", {
          api: "executeCanpayPayment",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Credit memo created", type: "success" },
            { root: true }
          );
          const order = response.data.body;
          commit("setPosOrder", order);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(order);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  archiveAllPayments: ({ commit, state }, payload) => {
    if (state.loadingStatus.archiveAllPayments) {
      return;
    }
    commit("setLoadingStatus", { api: "archiveAllPayments", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.archiveAllPayments,
      { order: state.posOrder },
      function (response, error) {
        commit("setLoadingStatus", {
          api: "archiveAllPayments",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Payments archived", type: "success" },
            { root: true }
          );
          const order = response.data.body;
          commit("setPosOrder", order);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(order);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  reverseOrder: ({ commit, state, rootGetters }, payload) => {
    const ws = rootGetters["posModule/selectedWorkstation"];
    const order = JSON.parse(JSON.stringify(state.order));
    if (payload && payload.isClone) {
      order.isClone = true;
    }
    order.notesVoid = payload.notesVoid;
    commit("setLoadingStatus", { api: "reverseOrder", status: true });
    HttpService.post(
      `${Apis.backendBaseUrl}${Apis.reverseOrder}`,
      { order, workstation: ws },
      function (response, error) {
        commit("setLoadingStatus", { api: "reverseOrder", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Reversed", type: "success" },
            { root: true }
          );
          const order = response.data.body;
          if (payload?.isClone) {
            commit("setPosOrder", order);
            router.push({
              name: "pos",
            });
          } else {
            commit("setOrder", order);
          }
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(order);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getOrderById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getOrderById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getOrderById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", { api: "getOrderById", status: false });
        const order = response.data.body;
        if (payload.isPos) {
          commit("setPosOrder", order);
        } else {
          commit("setOrder", order);
        }

        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(order);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getReceipt: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getReceipt", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.order.getReceipt + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", { api: "getReceipt", status: false });
        const order = response.data.body;
        if (payload.isPos) {
          commit("setPosOrder", order);
        } else {
          commit("setOrder", order);
        }

        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(order);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  print: ({ commit, state, rootGetters }, payload) => {
    const ws = rootGetters["posModule/selectedWorkstation"];
    if (!ws) {
      return commit(
        "setMessage",
        {
          text: "No workstation selected",
          type: "error",
          errorType: "no-workstation",
        },
        { root: true }
      );
    }
    commit("setLoadingStatus", { api: "print", status: true });

    var orderToPrint = state.order;
    if (payload.isPos) {
      orderToPrint = state.posOrder;
    }
    HttpService.post(
      Apis.backendBaseUrl + Apis.order.print,
      { order: orderToPrint, workstation: ws },
      function (response, error) {
        commit("setLoadingStatus", { api: "print", status: false });

        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback();
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error", errorType: error.errorCode == 1004 ? "no-workstation" : undefined },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  fulfill: ({ commit, state, rootGetters }, payload) => {
    commit("setLoadingStatus", { api: "fulfill", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.order.fulfill,
      { order: state.order },
      function (response, error) {
        commit("setLoadingStatus", { api: "fulfill", status: false });

        if (response) {
          commit("setOrder", response.data.body);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback();
          }
          commit(
            "setMessage",
            { text: "Order fulfilled", type: "success" },
            { root: true }
          );
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setOrder: ({ commit, state }, payload) => {
    commit("setOrder", payload);
  },
  setOrderTypeSelectionPopUp: ({ commit, state }, payload) => {
    commit("setOrderTypeSelectionPopUp", payload);
  },
  cancelOrder: ({ commit, state }, payload) => {
    commit("cancelOrder", payload);
  },
  assignCustomer: ({ commit, state, dispatch }, payload) => {
    commit("assignCustomer", payload);
    dispatch("saveOrder", {
      order: state.posOrder,
      positiveCallback: payload.positiveCallback,
    });
  },
  setPayment: ({ commit, state, dispatch }, payload) => {
    commit("setPayment", payload.payment);

    let apiToCall = "postOrder";
    if (payload?.payment?.type == "pre-order") {
      apiToCall = "saveOrder";
    }
    dispatch(apiToCall, {
      order: state.posOrder,
      positiveCallback: payload.positiveCallback,
      negativeCallback: payload.negativeCallback,
    });
  },
  unsetLoadingStatus: ({ commit, state, dispatch }, payload) => {
    state?.posOrder?.products.forEach((p) => {
      p.isLoading = false;
    });
  },
  applyDiscountToOrder: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      HttpService.post(
        Apis.backendBaseUrl + Apis.pos.applyDiscountToOrder,
        payload,
        function (response, error) {
          if (response) {
            const order = response.data.body;
            commit("setPosOrder", order);
            commit(
              "setMessage",
              { text: "Discount applied", type: "success" },
              { root: true }
            );
            resolve(order);
          } else {
            console.log(error);
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
            reject(error);
          }
        }
      );
    });
  },
  removeDiscountFromOrder: ({ commit, state }, payload) => {
    HttpService.post(
      Apis.backendBaseUrl + Apis.pos.removeDiscountFromOrder,
      payload,
      function (response, error) {
        if (response) {
          const order = response.data.body;
          commit("setPosOrder", order);
          commit(
            "setMessage",
            { text: "Discount removed", type: "success" },
            { root: true }
          );
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(order);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          if (payload && payload.negativeCallback) {
            payload.negativeCallback(error);
          }
        }
      }
    );
  },
  addRemoveItemToOrder: ({ commit, state, rootGetters }, payload) => {
    commit("setLoadingStatus", { api: "addRemoveItemToOrder", status: true });
    const workstation = rootGetters["posModule/selectedWorkstation"];
    return new Promise((resolve, reject) => {
      HttpService.post(
        Apis.backendBaseUrl + Apis.addRemoveItemToOrder,
        { order: state.posOrder, action: payload.action, workstation },
        function (response, error) {
          commit("setLoadingStatus", {
            api: "addRemoveItemToOrder",
            status: false,
          });
          if (response) {
            const order = response.data.body;
            commit("setPosOrder", order);
            if (payload && payload.positiveCallback) {
              payload.positiveCallback(response.data.body);
            }
            var message = "";
            if (payload.action && payload.action.mode === "add") {
              message = "Item successfully added";
            }
            if (payload.action && payload.action.mode === "subtract") {
              message = "Item successfully subtracted";
            }
            if (payload.action && payload.action.mode === "remove") {
              message = "Item successfully removed";
            }
            commit(
              "setMessage",
              {
                text: message,
                type: "success"
              },
              { root: true }
            );
            resolve()
          } else {
            if (payload && payload.negativeCallback) {
              payload.negativeCallback(error);
            }
            commit(
              "setMessage",
              {
                text: error.message,
                type: "error",
                errorType: error.errorCode == 1004 ? "no-workstation" : undefined
              },
              { root: true }
            );
            reject()
          }
        }
      );

    })
  },
  addItemToOrderByBarcode: ({ commit, state, rootGetters }, payload) => {
    commit("setLoadingStatus", {
      api: "addItemToOrderByBarcode",
      status: true,
    });
    const workstation = rootGetters["posModule/selectedWorkstation"];
    HttpService.post(
      Apis.backendBaseUrl + Apis.addItemToOrderByBarcode,
      { order: state.posOrder, action: payload.action, workstation },
      function (response, error) {
        commit("setLoadingStatus", {
          api: "addItemToOrderByBarcode",
          status: false,
        });
        if (response) {
          if (!payload.action.queryItem) {
            const order = response.data.body;
            commit("setPosOrder", order);
            commit(
              "setMessage",
              { text: "Item successfully added", type: "success" },
              { root: true }
            );
          }
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          if (payload && payload.negativeCallback) {
            payload.negativeCallback(error);
          }
          commit(
            "setMessage",
            {
              text: error.message,
              type: "error",
              errorType: error.errorCode == 1004 ? "no-workstation" : undefined
            },
            { root: true }
          );
        }
      }
    );
  },
  setOrderPopUp: ({ commit, state }, payload) => {
    commit("setOrderPopUp", payload);
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
  updateSort: ({ commit, state }, payload) => {
    commit("updateSort", payload);
  },
  updatePagination: ({ commit, state }, payload) => {
    commit("updatePagination", payload);
  },
  updateFilterButtons: ({ commit, state }, payload) => {
    commit("updateFilterButtons", payload);
  },
  updateInitialFilter: ({ commit, state }, payload) => {
    commit("updateInitialFilter", payload);
  },
  getAllOpenOrders: ({ commit, state }, payload) => {
    const filter = state.filterButtons.find(
      (f) => f.name == "open-orders"
    )?.filter;
    if (!filter) return;

    commit("setLoadingStatus", { api: "getAllOpenOrders", status: true });
    let url = `${Apis.backendBaseUrl}${Apis.order.filter}`;
    HttpService.post(
      url,
      { filter: filter, limit: 500 },
      function (response, error) {
        commit("setLoadingStatus", { api: "getAllOpenOrders", status: false });
        if (response && response.data) {
          const orders = response.data.body;
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(orders);
          }
        }
      }
    );
  },
  filter: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "filter", status: true });
    let url = `${Apis.backendBaseUrl}${Apis.order.filter}`;
    HttpService.post(
      url,
      { filter: payload.filter, limit: 500 },
      function (response, error) {
        commit("setLoadingStatus", { api: "filter", status: false });
        if (response && response.data) {
          const orders = response.data.body;
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(orders);
          }
        }
      }
    );
  },
  scanItem: ({ commit, state }, payload) => {
    const scannedBatchId = payload.batchId
    const product = findProductInOrder(state.posOrder, scannedBatchId)
    if (product) {
      commit("scanItem", { ...payload, product });
      if (payload.positiveCallback) {
        payload.positiveCallback()
      }
    } else {
      if (payload.negativeCallback) {
        payload.negativeCallback()
      }
    }
  },
  removeClutchDiscountFromOrder: ({ commit, state }, payload) => {
    // Prevent doubleclick
    if (state.loadingStatus.removeClutchDiscountFromOrder) {
      return
    }

    commit("setLoadingStatus", { api: "removeClutchDiscountFromOrder", status: true });

    HttpService.post(
      `${Apis.backendBaseUrl}${Apis.pos.removeClutchDiscountFromOrder}`,
      {
        _entity: "order",
        _id: state.posOrder?._id,
        orderId: state.posOrder?._id,
        ...payload
      },
      function (response, error) {
        commit("setLoadingStatus", { api: "removeClutchDiscountFromOrder", status: false });
        const order = response.data.body;
        commit("setPosOrder", order);
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback();
          }
          commit(
            "setMessage",
            { text: "Coupon cancelled", type: "success" },
            { root: true }
          );
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  resetScanCounters: ({ commit }) => {
    commit("resetScanCounters");
  },
  processPrices: ({ commit, state, rootGetters }, payload) => {

    const body = {
      _entity: "order",
      updatedAt: payload.order.updatedAt,
      _id: payload.order._id
    }

    commit("setLoadingStatus", { api: "processPrices", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.pos.processPrices,
      body,
      function (response, error) {
        commit("setLoadingStatus", { api: "processPrices", status: false });
        if (response) {
          const order = response.data.body;
          commit("setPosOrder", order);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          if (payload && payload.negativeCallback) {
            payload.negativeCallback(response);
          }
        }
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
