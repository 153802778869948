var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Customer Credit Memo Status","links":[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'customerReport', label: 'Customer' },
        {
          name: 'customerCreditMemoBalance',
          label: 'Customer Credit Memo Status',
        } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByMemoType":true,"isLoading":_vm.customerTransactions},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((_vm.reportData.records),function(record,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(record.date))]),_c('td',[_vm._v(_vm._s(record.memoType))]),_c('td',[_vm._v(_vm._s(record.memoTypeCashReason))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(record.memoBalance)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(record.memoAmountUsed)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(record.memoTotal)))]),_c('td',[_vm._v(_vm._s(record.notes))]),_c('td',[_vm._v(_vm._s(record.customerName))]),_c('td',[_vm._v(_vm._s(record.customerLastName))]),_c('td',[_vm._v(_vm._s(record.customerEmail))]),_c('td',[_vm._v(_vm._s(record.supplierName))]),_c('td',[_vm._v(_vm._s(record.supplier))]),_c('td',[_vm._v(_vm._s(record.storeName))]),_c('td',[_vm._v(_vm._s(record.id))])])})],2),_vm._m(1),_c('tr',[_c('td',{attrs:{"colspan":"3","align":"left"}},[_vm._v("Totals")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.memoBalance)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.memoAmountUsed)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.memoTotal)))])]),_c('tr',{staticClass:"skip-export"}),_vm._m(2)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"report-body__row report-body__row--header"}),_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Date")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Type")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Cash Reason")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Balance")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Amount Used")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Total")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Notes")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Customer Name")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Customer Last Name")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Customer Email")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Supplier Name")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Supplier")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Store")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Id")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])}]

export { render, staticRenderFns }