// Store Editor
import * as Entities from "@/resources/Entities";
import Constants from "@/resources/Constants";

import {
  filterCriteria as roomFilterCriteria,
  searchColumnHeaders as roomColumnHeaders,
} from "../Room/inputs";
// Page Header
export const pageHeader = {
  title: "Store",
  isEditorView: true,
  cancelPath: "stores",
  addPath: "storeEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Stores",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "storeEditor",
};

// Search Filter Criteria
export const filterCriteria = [
  { label: "Name", code: "name", field: { type: "input", label: "Name" }, isPartial: true, },
  { label: "Id", code: "_id", field: { type: "input", label: "Id" }, isPartial: true, },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Name",
    id: "name",
    size: "large",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Created By",
    id: "createdBy",
    size: "large",
  },
  {
    title: "Updated By",
    id: "updatedBy",
    size: "large",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Name",
    id: "name",
    size: "large",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Created By",
    id: "createdBy",
    size: "large",
  },
  {
    title: "Updated By",
    id: "updatedBy",
    size: "large",
  },
];

export const storeInputs = [
  {
    id: 1,
    inputs: [
      {
        id: "name",
        type: "input",
        title: "Store Name",
        placeholder: "Store Name",
        class: "grid-row-two-column",
        focus: true,
      },
      {
        id: "address",
        type: "input",
        title: "Address",
        placeholder: "Address",
        class: "grid-row-two-column",
      },
      {
        id: "city",
        placeholder: "City",
        type: "input",
        title: "City",
      },
      {
        id: "state",
        placeholder: "State",
        type: "select",
        title: "State",
        options: Constants.listOfStates,
      },
      {
        id: "zip",
        placeholder: "Zip",
        type: "mask",
        title: "Zip",
        mask: Constants.inputMasks.defaultZip,
      },
      {
        id: "county",
        placeholder: "County",
        type: "input",
        title: "County",
      },
      {
        id: "phone",
        type: "mask",
        title: "Phone",
        mask: Constants.inputMasks.defaultPhone,
      },
      {
        id: "timeZone",
        type: "input",
        title: "Time Zone",
        placeholder: "Time Zone",
      },
      {
        id: "email",
        placeholder: "Email",
        type: "input",
        title: "Email",
        class: "grid-row-two-column",
      },
      {
        id: "site",
        placeholder: "Website",
        type: "input",
        title: "Website",
        class: "grid-row-two-column",
      },
    ],
  },
  {
    id: 2,
    inputs: [
      {
        id: "isMedical",
        type: "checkBox",
        title: "Medical Store",
      },
      {
        id: "isRecreational",
        type: "checkBox",
        title: "Recreational Store",
      },
      {
        id: "numberOfDrawers",
        type: "mask",
        title: "Number of Drawers",
        mask: "##",
      },
      {
        id: "cashReconciliationType",
        type: "select",
        title: "Cash Reconciliation By",
        placeholder: "Select Type",
        label: "label",
        options: ["station", "employee"],
        disableFunction: (item) => {
          return item.cashReconciliationType != null;
        },
      },
      {
        id: "notes",
        placeholder: "Notes",
        type: "textarea",
        title: "Notes",
        class: "grid-row-two-column",
      },
    ],
  },
];

export const traceabilityTypes = [
  {
    id: "traceabilitySystem",
    inputs: [
      {
        id: "traceabilitySystem",
        type: "select",
        title: "Traceability System",
        placeholder: "Select Account Type",
        label: "label",
        options: Constants.listOfCompatibleTraceabilitySystems,
      },
    ],
  },
]

export const traceabilityInputs = [

  {
    id: "biotrack",
    inputs: [
      {
        id: "medicalCannabisLimit",
        type: "money",
        suffix: "g",
        title: "Medical Cannabis Limit",
        precision: Constants.numericInput.defaultWeightPrecision,
      },
      {
        id: "medicalPeriodLimit",
        type: "mask",
        title: "Medical Limit (days)",
        mask: Constants.inputMasks.numberOfDays,
      },
      {
        id: "ubi",
        type: "input",
        title: "UBI",
        placeholder: "UBI",
      },
      {
        id: "location",
        type: "input",
        title: "Location",
        placeholder: "Location",
      },
      {
        id: "traceabilityDefaultUser",
        type: "input",
        title: "Default User",
        placeholder: "Default User",
      },
      {
        id: "traceabilityDefaultPassword",
        type: "password",
        title: "Default Password",
        placeholder: "Default Password",
      },
    ],
  },
  {
    id: "metrc",
    inputs: [
      {
        id: "traceabilityStoreKey",
        type: "input",
        title: "Store ID",
        placeholder: "Store ID",
      },
      {
        id: "traceabilityMetrcUsername",
        type: "input",
        title: "Username",
        placeholder: "Username",
      },
      {
        id: "traceabilityMetrcPassword",
        type: "input",
        title: "Password",
        placeholder: "Password",
      },
    ],
  },
];

export const posInputs = [
  {
    id: "defaultDisposalRoom",
    placeholder: "Select Room",
    type: "search",
    title: "Default Disposal Room",
    label: "name",
    entity: Entities.ROOM,
    columnHeaders: roomColumnHeaders,
    filterCriteria: roomFilterCriteria,
  },
  {
    id: "endOfFiscalYearMonth",
    placeholder: "Select Month",
    type: "select",
    title: "End of Fiscal Year [month]",
    options: Constants.listOfMonths,
    label: "label",
  },
  {
    id: "endOfFiscalYearDay",
    placeholder: "Select Day",
    type: "select",
    title: "End of Fiscal Year [day]",
    options: Constants.listOfMonthDays,
    label: "label",
  },
  {
    id: "isPrePackagedInventoryOnlyEnabled",
    type: "checkBox",
    title: "Pre-Packaged Inventory Only",
  },
  // this is to change the working inventory
  // {
  //   id: "isWorkingInventoryOnlyEnabled",
  //   type: "checkBox",
  //   title: "Sell items in Working Inventory Only",
  // },
  // {
  //   id: "isWorkingInventoryOnlyEnabledForNonCannabis",
  //   type: "checkBox",
  //   title: "Sell items in Working Inventory Only (non-cannabis)",
  // },
  {
    id: "isShowQuantityBoxEnabled",
    type: "checkBox",
    title: "Show Quantity Box",
    checkboxLabel: "Show Quantity Box",
  },
  {
    id: "isPinAlwaysRequired",
    type: "checkBox",
    title: "Always Ask PIN",
    checkboxLabel: "Always Ask PIN",
  },
  {
    id: "isShowSupplierFirstEnabled",
    type: "checkBox",
    title: "Show Supplier First",
  },
  {
    id: "isNonCannabisAllInTaxEnabled",
    type: "checkBox",
    title: "Non Cannabis All-In Tax",
  },
  {
    id: "isCannabisAllInTaxEnabled",
    type: "checkBox",
    title: "Cannabis All-In Tax",
  },
  {
    id: "isDriverLicenseScanningEnabled",
    type: "checkBox",
    title: "Enable Driver's License Scanning",
  },
  {
    id: "printBarcodeOnCheckIn",
    type: "checkBox",
    title: "Print Barcode on Check-in",
  },
  {
    id: "printPreOrderAtCheckIn",
    type: "checkBox",
    title: "Print Pre-Order to Fulfillment at Check-in",
  },
  {
    id: "isPOSScanningEnabled",
    type: "checkBox",
    title: "Scan Items at POS",
  },
  {
    id: "disablePrintOrdersToFullfilmentUponPosting",
    type: "checkBox",
    title: "Do not Print Orders to Fulfillment upon Posting",
  },
  {
    id: "allowInstantDiscountsToManagersOnly",
    type: "checkBox",
    title: "Allow Instant Discounts to managers Only",
  },
  {
    id: "lowStockWorkingInventory",
    title: "Low Stock Working Inventory",
    type: "number",
    precision: 0,
  },

];

export const canPayIntegrationInputs = [
  {
    id: "enableCanPayRetail",
    type: "checkBox",
    title: "Enable CanPay Retail",
  },
  {
    id: "canpayRetailAppKey",
    type: "input",
    title: "Retail App Key",
  },
  {
    id: "canpayRetailApiSecret",
    type: "input",
    title: "Retail Api Secret",
  },
  {
    id: "isCanpayEnabled",
    type: "checkBox",
    title: "Enable Canpay Remote (Online Store)",
  },
  {
    id: "canpayRemoteAppKey",
    type: "input",
    title: "Remote App Key",
  },
  {
    id: "canpayRemoteApiSecret",
    type: "input",
    title: "Remote Api Secret",
  },
];

export const loyaltyProgramInputs = [
  {
    id: "loyaltySystem",
    title: "Loyalty System",
    type: "select",
    placeholder: "Choose the Loyalty System",
    options: [
      {
        label: "Dispense First",
        code: "dispense-first",
      },
      {
        label: "Clutch",
        code: "clutch",
      },
      {
        label: "Springbig",
        code: "springbig",
      },
      {
        label: "Alpine IQ",
        code: "alpine",
      }
    ]
  },
  {
    id: "applyCustomerDiscountToSaleItems",
    type: "checkBox",
    title: "Apply Customer Discount to Sale Items",
    checkboxLabel: "Apply Customer Discount to Sale Items",
  },
  {
    id: "isVeteranDiscountEnabled",
    type: "checkBox",
    title: "Enable Veteran Discount",
    checkboxLabel: "Veteran Discount",
  },
  {
    id: "veteranDiscount",
    type: "number",
    suffix: "%",
    title: "Veteran Discount Amount",
    precision: Constants.numericInput.moneyPrecision,
  },
  {
    id: "isCcpDiscountEnabled",
    type: "checkBox",
    title: "Enable CCP Discount",
    checkboxLabel: "CCP Discount",
  },
  {
    id: "ccpDiscount",
    type: "money",
    title: "CCP Discount Amount",
    precision: Constants.numericInput.moneyPrecision,
  },
  {
    id: "isPointProgramEnabledForMedical",
    type: "checkBox",
    title: "Point Program Active for Medical",
    checkboxLabel: "Medical Active",
  },
  {
    id: "isPointProgramEnabledForRecreational",
    type: "checkBox",
    title: "Point Program Active for Recreational",
    checkboxLabel: "Recreational Active",
  },
  {
    id: "isPointProgramEnabledForCannabisOnly",
    type: "checkBox",
    title: "Award Points on Cannabis Products Only",
    checkboxLabel: "Cannabis Only",
  },
  {
    id: "isAwardPointsOnTax",
    type: "checkBox",
    title: "Award Points on Tax",
    checkboxLabel: "Points Awarded On Tax",
  },
  {
    id: "pointsPerDollarSpent",
    type: "input",
    title: "Points Earned per Dollar Spent",
    checkboxLabel: "Points/Dollar Spent",
  },
  {
    id: "isLevel1PointProgramEnabled",
    type: "checkBox",
    title: "Level 1 Point Program Active",
    checkboxLabel: "Level 1 Active",
  },
  {
    id: "pointsDiscountLevel1",
    type: "input",
    title: "Level 1 Points Required for Reward",
  },
  {
    id: "pointsAmountLevel1",
    type: "money",
    title: "Level 1 Reward Amount",
    precision: Constants.numericInput.moneyPrecision,
  },
  {
    id: "isLevel2PointProgramEnabled",
    type: "checkBox",
    title: "Level 2 Point Program Active",
    checkboxLabel: "Level 2 Active",
  },
  {
    id: "pointsDiscountLevel2",
    type: "input",
    title: "Level 2 Points Required for Reward",
  },
  {
    id: "pointsAmountLevel2",
    type: "money",
    title: "Level 2 Reward Amount",
    precision: Constants.numericInput.moneyPrecision,
  },
  {
    id: "showLoyaltyOptIn",
    type: "checkBox",
    title: "Show Loyalty Opt-In Message",
    checkboxLabel: "Show Loyalty Opt-In Message",
  },
];

export const clutchLoyaltyProgramInputs = [
  {
    id: "clutch.brand",
    type: "input",
    title: "Clutch Brand",
    placeholder: "Clutch Brand",
  },
  {
    id: "clutch.location",
    type: "input",
    title: "Clutch Location",
    placeholder: "Clutch Location",
  },
  {
    id: "clutch.terminal",
    type: "input",
    title: "Clutch Terminal",
    placeholder: "Clutch Terminal",
  },
  {
    id: "clutch.cardSetId",
    type: "input",
    title: "Clutch Card Set Id",
    placeholder: "Clutch Card Set Id",
  },
  {
    id: "clutch.ftpPath",
    type: "input",
    title: "FTP path",
    placeholder: "FTP path",
  },
  {
    id: "clutch.ftpUser",
    type: "input",
    title: "FTP User",
    placeholder: "FTP User",
  },
];

export const alpineIqLoyaltyProgramInputs = [
  {
    id: "alpineIq.apiKey",
    type: "input",
    title: "Alpine IQ API Key",
    placeholder: "Api Key",
  },
  {
    id: "alpineIq.userId",
    type: "input",
    title: "Alpine IQ User Id",
    placeholder: "User Id",
  }
];

export const paymentTerminalIntegrationInputs = [
  {
    id: "paymentTerminalIntegration.type",
    title: "Integration Type",
    type: "select",
    placeholder: "Select Integration",
    options: [
      {
        label: "TransactFirst",
        code: "transact-first",
      }
    ]
  },
  {
    id: "paymentTerminalIntegration.authKey",
    type: "input",
    title: "AuthKey",
    placeholder: "",
  },
]

export const paymentTerminalInputs = [
  {
    id: "name",
    type: "input",
    title: "Name",
    placeholder: "Name",
  },
  {
    id: "registerId",
    type: "input",
    title: "Register #",
    placeholder: "Register #",
  },
]

export const workstationInputs = [
  {
    id: "name",
    type: "input",
    title: "Name",
    placeholder: "Name",
  },
  {
    id: "printer.ip",
    type: "input",
    title: "Receipt Printer CUP name",
    placeholder: "Receipt Printer CUP name",
  },
  {
    id: "printer.type",
    type: "select",
    title: "Printer Type",
    placeholder: "Printer Type",
    options: [
      {
        label: "CUP",
        code: "CUP",
      }]
  },
  {
    id: "printer.cut",
    type: "checkBox",
    title: "Perform Cut",
    checkboxLabel: "Perform Cut",
  },
  {
    id: "printer.openCashDrawer",
    type: "checkBox",
    title: "Open Cash Drawer",
    checkboxLabel: "Open Cash Drawer",
  },
  {
    id: "isMedical",
    type: "checkBox",
    title: "Medical",
    checkboxLabel: "Medical",
  },
  {
    id: "isRecreational",
    type: "checkBox",
    title: "Rec",
    checkboxLabel: "Rec",
  },
  {
    id: "isFulfillment",
    type: "checkBox",
    title: "Fulfillment",
    checkboxLabel: "Fulfillment Workstation",
  },
  {
    id: "paymentTerminalId",
    type: "select",
    placeholder: "Select Payment terminal",
    options: [],
    title: "Payment Terminal",
  },
];

export const carouselEntryInputs = [
  {
    id: "href",
    type: "input",
    title: "Link",
    placeholder: "Link",
  },
  {
    id: "shouldOpenInNewTab",
    type: "checkbox",
    title: "New Tab",
  }
];

export const labelPrinterInputs = [
  {
    id: "name",
    type: "input",
    title: "Name",
    placeholder: "Name",
  },
  {
    id: "printer.ip",
    type: "input",
    title: "Label Printer IP",
    placeholder: "Receipt Printer IP",
  },
  {
    id: "printer.type",
    type: "select",
    title: "Printer Type",
    placeholder: "Printer Type",
    options: [
      {
        label: "Zebra",
        code: "ZEBRA",
      }]
  },
  {
    id: "printer.layout",
    type: "select",
    title: "Layout",
    placeholder: "Layout",
    options: [
      {
        label: "Bigger Barcode",
        code: "bigger-barcode",
      }, {
        label: "Bigger Name",
        code: "bigger-name",
      }, {
        label: "Bigger Batch",
        code: "bigger-batch",
      }]
  },
];

export const salesReciptBeginInputs = [
  {
    id: "receiptLogo.secure_url",
    placeholder: "Image URL",
    type: "input",
    title: "Image URL",
  },
  {
    id: "printCompanyNameOnReceipt",
    type: "checkBox",
    title: "Print Company Name",
  },
  {
    id: "printLevel1PointsOnReceipt",
    type: "checkBox",
    title: "Print Level 1 Points",
  },
  {
    id: "printLevel2PointsOnReceipt",
    type: "checkBox",
    title: "Print Level 2 Points",
  },
  {
    id: "printLifetimePointsOnReceipt",
    type: "checkBox",
    title: "Print Lifetime Points",
  },
  {
    id: "printBatchNumbersOnReceipt",
    type: "checkBox",
    title: "Print Batch Number",
  },
  {
    id: "itemizedDiscountsOnReceipt",
    type: "checkBox",
    title: "Itemize Discounts",
  },
  {
    id: "itemizedTaxesOnReceipt",
    type: "checkBox",
    title: "Itemize Taxes",
  },
  {
    id: "printPOSReceiptCopies",
    type: "input",
    title: "Receipt - Number of Copies",
  },
  {
    id: "receiptFooter",
    placeholder: "Receipt Footer",
    type: "textarea",
    title: "Receipt Footer",
    class: "flex-break-row grid-notes",
  },
  {
    id: "receiptQrCodeText",
    placeholder: "",
    type: "textarea",
    title: "QR Code Text",
    class: "flex-break-row grid-notes",
  },
  {
    id: "receiptQrCodeHyperlink",
    placeholder: "",
    type: "input",
    title: "QR Code Hyperlink Override - Default is to Online Store create/update account.",
    class: "flex-break-row grid-notes",
  },
];


export const kioskInputs = [
  {
    id: "kiosk.kioskViewOnlyMode",
    type: "checkBox",
    title: "Show in view only mode",
  },
  {
    id: "kiosk.showItemsAlsoNotInWorkingInventory",
    type: "checkBox",
    title: "Show also Items available but not in working inventory",
  },
  {
    id: "kiosk.printPreOrderAtFullfilmentWhenOrderPlaced",
    type: "checkBox",
    title: "Print order placed at the Kiosk to fulfillment ",
  },
  {
    id: "kiosk.showPreTaxPricing",
    type: "checkBox",
    title: "Show Pre Tax Price",
  },
  {
    id: "kiosk.showThca",
    type: "checkBox",
    title: "Show THCA on Product Card",
  },
  {
    id: "kiosk.showThcCbdFilters",
    type: "checkBox",
    title: "Show THC & CBD sliders in filters",
  },
  {
    id: "kiosk.navigateToScanIn",
    type: "checkBox",
    title: "Show Scan In Page before entering Menu",
  },
];

export const leaflyIntegrationInputs = [
  {
    id: "leafly.isEnabled",
    type: "checkBox",
    title: "Enable",
  },
  {
    id: "leafly.apiKeyAuth",
    type: "input",
    inputType: "password",
    title: "API Key Auth",
  },
  {
    id: "leafly.menuIntegrationKey",
    type: "input",
    inputType: "password",
    title: "Menu Integration Key",
  },
];

export const weedmapsIntegrationInputs = [
  {
    id: "weedmaps.isEnabled",
    type: "checkBox",
    title: "Enable",
  },
  {
    id: "weedmaps.wmid",
    type: "input",
    inputType: "text",
    title: "Menu Id",
  },
];

export const greenCheckIntegrationInputs = [
  {
    id: "greenCheck.isEnabled",
    type: "checkBox",
    title: "Enable",
  },
  {
    id: "greenCheck.ftpPath",
    type: "input",
    inputType: "text",
    title: "Host",
  },
  {
    id: "greenCheck.remoteDirectory",
    type: "input",
    inputType: "text",
    title: "Remote Directory",
  },
  {
    id: "greenCheck.ftpUser",
    type: "input",
    inputType: "text",
    title: "User",
  },
  {
    id: "greenCheck.key",
    type: "input",
    inputType: "password",
    title: "Password",
  },
];

export const quickBooksIntegrationInputs = [
  {
    id: "quickBooks.isEnabled",
    type: "checkBox",
    title: "Enable",
  },
  {
    id: "quickBooks.apiKeyAuth",
    type: "input",
    inputType: "password",
    title: "API Key Auth",
  },
  {
    id: "quickBooks.menuIntegrationKey",
    type: "input",
    inputType: "password",
    title: "Menu Integration Key",
  },
  {
    id: "quickBooks.isSandboxEnvironment",
    type: "checkBox",
    title: "Use Sandbox Environment",
  }
];
export const netSuiteIntegrationInputs = [
  {
    id: "netSuite.isEnabled",
    type: "checkBox",
    title: "Enable",
  },
  {
    id: "netSuite.subsidiary",
    type: "input",
    inputType: "text",
    title: "Subsidiary",
  },
  {
    id: "netSuite.class",
    type: "input",
    inputType: "text",
    title: "Class",
  },
  {
    id: "netSuite.apiKeyAuth",
    type: "input",
    inputType: "password",
    title: "API Key Auth",
  },
  {
    id: "netSuite.menuIntegrationKey",
    type: "input",
    inputType: "password",
    title: "Menu Integration Key",
  },
];

export const generalSettingsInputs = [
  {
    id: "isInventoryCountEnabled",
    type: "checkBox",
    title: "Pause Automatic Insert of Cart Orders",
  },
  {
    id: "locationIndicator",
    type: "input",
    title: "Location Indicator",
  },
  {
    id: "isProductPricePublic",
    type: "checkBox",
    title: "Show Product Price to Visitors",
  },
  {
    id: "isMedicalOnlineSaleEnabled",
    type: "checkBox",
    title: "Medical Sale",
  },
  {
    id: "isRecreationalOnlineSaleEnabled",
    type: "checkBox",
    title: "Recreational Sale",
  },
  {
    id: "postOrderingRedirectUrl",
    type: "input",
    title: "After Order Placement, Redirect to this Address:",
    placeholder: "https://example.com",
  },
  {
    id: "webPrivacyDocumentUrl",
    type: "input",
    title: "Notice of Privacy Practices url:",
    placeholder: "https://example.com",
  },
  {
    id: "isArrowAffordanceVisible",
    type: "checkBox",
    title: "Show Arrow on Category Menu",
  },
  {
    id: "showPreTaxPricing",
    type: "checkBox",
    title: "Show Prices Pre Tax",
  },
  {
    id: "showInOnlineStoreDropdown",
    type: "checkBox",
    title: "Show Store in Locations Dropdown",
  },
  {
    id: "isAutomaticOnlineStorePrintingEnabled",
    type: "checkBox",
    title: "Automatically print orders to fulfillment",
  },
  {
    id: "lowStockEnabled",
    type: "checkBox",
    title: "Show Low Stock Tag Automatically",
  },
  {
    id: "lowStockTrashold",
    title: "Low Stock Threshold",
    type: "number",
    precision: 0,
  },
  {
    id: "showThca",
    type: "checkBox",
    title: "Show THCA on Product Card",
  },
  {
    id: "showOnlineStoreThcCbdFilters",
    type: "checkBox",
    title: "Show THC & CBD sliders in filters",
  },
];

export const ticketInputs = [
  {
    id: "isTicketEnabledForMedical",
    type: "checkBox",
    title: "Enable Medical Ticket",
    checkboxLabel: "Medical Ticket",
  },
  {
    id: "medicalOrderTicket",
    type: "input",
    title: "Medical Order Ticket",
  },
  {
    id: "isTicketEnabledForRecreational",
    type: "checkBox",
    title: "Enable Recreational Ticket",
    checkboxLabel: "Recreational Ticket",
  },
  {
    id: "recreationalOrderTicket",
    type: "input",
    title: "Recreational Order Ticket",
  },
];

export const messagesInputs = [
  {
    id: "storeBanner",
    type: "textarea",
    title: "Banner",
  },
  {
    id: "registrationAcceptanceNotice",
    type: "textarea",
    title: "Registration Acceptance",
  },
  {
    id: "registrationMarketingOptInNotice",
    type: "textarea",
    title: "Marketing Opt In Acceptance",
  },
  {
    id: "enrollLoyaltyCheckboxText",
    type: "input",
    title: "Enroll into Loyalty Checkbox text",
  },
  {
    id: "faq",
    type: "textarea",
    title: "FAQ",
  },
  {
    id: "loyaltyExternalLinkText",
    type: "input",
    title: "Loyalty Link Text",
  },
  {
    id: "loyaltyExternalLink",
    type: "input",
    title: "Loyalty Link URL",
  },
];

export const aspectInputs = [
  {
    id: "isFontColorBlack",
    type: "checkBox",
    title: "Font Color Black",
    checkboxLabel: "Black",
  },
  {
    id: "templateColor",
    type: "input",
    title: "Template Color",
    placeholder: "#999",
  },
  {
    id: "siteUrl",
    type: "input",
    title: "Site Url",
    placeholder: "https://yourdomain.extension",
  },
  {
    id: "siteTitle",
    type: "input",
    title: "Site Title",
  },
  {
    id: "siteDescription",
    type: "input",
    title: "Site Description",
  },
  {
    id: "siteFooterName",
    type: "input",
    title: "Site Footer Name",
  },
  {
    id: "logo.secure_url",
    placeholder: "Logo Image URL",
    type: "input",
    title: "Logo Image URL",
  },
  {
    id: "bundleImage.secure_url",
    placeholder: "Bundle Image URL",
    type: "input",
    title: "Bundle Image URL",
  },
  {
    id: "productImagePlaceholder.secure_url",
    placeholder: "Placeholder Image URL",
    type: "input",
    title: "Placeholder Image URL",
  },
  {
    id: "favicon.secure_url",
    placeholder: "Favicon Image URL",
    type: "input",
    title: "Favicon Image URL",
  },
];

export const emailInputs = [
  {
    id: "orderConfirmationEmailSender",
    type: "input",
    title: "Order Confirmation Sender",
    placeholder: "orders@email.com",
  },
  {
    id: "orderNotificationEmailSender",
    type: "input",
    title: "Order Notification Sender",
  },
  {
    id: "orderConfirmationEmailHeader",
    type: "input",
    title: "Order Confirmation Header",
  },
  {
    id: "orderNotificationEmailHeader",
    type: "input",
    title: "Order Notification Header",
  },
  {
    id: "orderConfirmationEmailBody",
    type: "textarea",
    title: "Order Confirmation Body",
  },
  {
    id: "orderNotificationEmailBody",
    type: "textarea",
    title: "Order Notification Body",
  },
  {
    id: "orderCancellationEmailHeader",
    type: "input",
    title: "Order Cancellation Header",
  },
];

export const pickupWindowInput = {
  id: "selectedPickupWindowAccountType",
  type: "select",
  title: "Pickup Window For",
  placeholder: "Select Account Type",
  label: "label",
  options: [],
};

export const pickupSchedulerInputs = [
  {
    id: "selectedPickupSchedulerAccountType",
    type: "select",
    title: "Pickup Scheduler For",
    placeholder: "Select Account Type",
    label: "label",
    options: [],
  },
  {
    id: "isRecreationalPickupSchedulerEnabled",
    type: "checkBox",
    title: "Use Pickup Window For Recreational",
    checkboxLabel: "Use Pickup Window",
  },
  {
    id: "isMedicalPickupWindowEnabled",
    type: "checkBox",
    title: "Use Pickup Window For Medical",
    checkboxLabel: "Use Pickup Window",
  },
];

export const selectedSchedulerDayInput = {
  id: "selectedSchedulerDay",
  placeholder: "Select Day",
  type: "select",
  title: "Day of the Week",
  options: Constants.listOfWeekDays,
  label: "label",
};

export const timeSlotInputs = [
  {
    id: "timeSlotDuration",
    placeholder: "Select Time Slots Duration",
    type: "select",
    title: "Time Slots Duration",
    options: Constants.listOfDuration,
  },
  {
    id: "leadway",
    placeholder: "Select Time Slots Duration",
    type: "select",
    title: "Leadway",
    options: Constants.listOfDurations,
    label: "label",
  },
  {
    id: "capacity",
    type: "mask",
    title: "Slot Capacity [clients]",
    placeholder: "Capacity",
    mask: Constants.inputMasks.shortNumber,
  },
  {
    id: "allowMedicalSchedulerSellAfterClosing",
    type: "checkBox",
    title: "Allow Order After Closing",
    checkboxLabel: "Allow",
  },
];

export const schedulerSlotsInput = {
  id: "isEnabled",
  type: "checkBox",
  label: "label",
};

export const emailSetUpInputs = [
  {
    id: "emailServer",
    type: "input",
    title: "Email Server",
    placeholder: "Server",
  },
  {
    id: "emailPort",
    title: "Port",
    placeholder: "Port",
    type: "mask",
    mask: Constants.inputMasks.networkPort,
  },
  {
    id: "emailUser",
    type: "input",
    title: "Email User",
    placeholder: "Email User",
  },
  {
    id: "emailPassword",
    type: "input",
    title: "Email Password",
    placeholder: "Email Password",
    inputType: "password",
  },
  {
    id: "emailApiKey",
    type: "input",
    title: "Api Key",
    placeholder: "",
    inputType: "password",
  },
  {
    id: "emailProvider",
    placeholder: "Email Provider",
    type: "select",
    title: "Email Provider",
    options: [
      {
        label: "Mandrill",
        code: "mandrill",
      }, {
        label: "Gmail",
        code: "gmail",
      }, {
        label: "SendGrid",
        code: "sendgrid",
      }]
  },

];

export const externalAPIInputs = [
  {
    id: "externalApi.isEnabled",
    type: "checkBox",
    title: "Enable",
  },
];

export const defaultSelectedStore = {
  _id: "NEW",
  name: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  county: "",
  phone: "",
  email: "",
  notes: "",
  timeZone: "",
  isMedical: true,
  isRecreational: false,
  isMedicalOnlineSaleEnabled: false,
  isRecreationalOnlineSaleEnabled: false,
  isProductPricePublic: false,
  isArrowAffordanceVisible: false,
  isRecreationalPickupWindowEnabled: false,
  isMedicalPickupWindowEnabled: false,
  isRecreationalPickupSchedulerEnabled: false,
  isMedicalPickupSchedulerEnabled: false,
  recreationalPickupSchedulerSlotDuration: undefined,
  medicalPickupSchedulerSlotDuration: undefined,
  allowMedicalSchedulerSellAfterClosing: false,
  allowRecreationalSchedulerSellAfterClosing: false,
  postOrderingRedirectUrl: "",
  orderConfirmationEmailSender: "",
  orderConfirmationEmailHeader: "",
  orderConfirmationEmailBody: "",
  orderNotificationEmailSender: "",
  orderNotificationEmailHeader: "",
  orderNotificationEmailBody: "",
  orderCancellationEmailHeader: "",
  medicalOrderTicket: "",
  recreationalOrderTicket: "",
  isTicketEnabledForMedical: false,
  isTicketEnabledForRecreational: false,
  disclaimer: "",
  registrationAcceptanceNotice: "",
  registrationMarketingOptInNotice: "",
  loginHelp: "",
  storeHelp: "",
  locationIndicator: "",
  productImagePlaceholder: {},
  bundleImage: {},
  logo: {},
  receiptLogo: {},
  templateColor: "",
  isFontColorBlack: true,
  numberOfDrawers: 2,
  receiptFooter: "",
  printCompanyNameOnReceipt: false,
  printLevel2PointsOnReceipt: false,
  printLifetimePointsOnReceipt: false,
  printBatchNumbersOnReceipt: true,
  itemizedDiscountsOnReceipt: false,
  itemizedTaxesOnReceipt: false,
  isVeteranDiscountEnabled: false,
  veteranDiscount: 0,
  isCcpDiscountEnabled: false,
  ccpDiscount: 0,
  isLevel1PointProgramEnabled: false,
  isLevel2PointProgramEnabled: false,
  isPointProgramEnabledForMedical: false,
  isPointProgramEnabledForRecreational: false,
  isPointProgramEnabledForCannabisOnly: false,
  isAwardPointsOnTax: false,
  pointsPerDollarSpent: 1,
  pointsDiscountLevel1: 500,
  pointsDiscountLevel2: 2500,
  pointsAmountLevel1: 25,
  pointsAmountLevel2: 50,
  traceabilitySystem: "",
  traceabilityRegisteredName: "",
  ubi: "",
  location: "",
  inStateRecreationalAcNumber: "",
  outOfStateRecreationalAcNumber: "",
  medicalCannabisLimit: 0,
  medicalPeriodLimit: 0,
  isShowQuantityBoxEnabled: false,
  isShowSupplierFirstEnabled: false,
  isNonCannabisAllInTaxEnabled: true,
  isPrePackagedInventoryOnlyEnabled: true,
  isWorkingInventoryOnlyEnabled: true,
  defaultDisposalRoom: {},
  isAutomaticOnlineStorePrintingEnabled: false,
  isDriverLicenseScanningEnabled: true,
  endOfFiscalYearMonth: { label: "12", code: "12" },
  endOfFiscalYearDay: { label: "31", code: "31" },
  workstations: [],
  labelPrinters: [],
  paymentTerminals: [],
  emailServer: "",
  emailPort: "",
  emailUser: "",
  emailPassword: "",
  emailApiKey: '',
  loyaltySystem: 'dispense-first',
  showPreTaxPricing: false,
  showThca: false,
  leafly: {
    isEnabled: false,
    apiKeyAuth: '',
    menuIntegrationKey: ''
  },
  externalApis: {
    isEnabled: false
  },
  kiosk: {
    kioskViewOnlyMode: false,
    showItemsAlsoNotInWorkingInventory: false,
    showPreTaxPrice: false
  },
  showInOnlineStoreDropdown: true,
  printPreOrderAtCheckIn: false,
  disablePrintOrdersToFullfilmentUponPosting: false,
  allowInstantDiscountsToManagersOnly: false,
  lowStockEnabled: false,
  lowStockTrashold: 10,
  lowStockWorkingInventory: 5,
  greenCheck: {
    isEnabled: false,
    key: '',
    ftpPath: '',
    ftpUser: ''
  },
};
