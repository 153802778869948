export const normalizeEntityBeforeSaving = (purchaseOrder) => {
  const entity = JSON.parse(JSON.stringify(purchaseOrder));
  if (entity.batchType) {
    entity.batchType = entity.batchType.code;
  }
  if (entity.items) {
    entity.items.forEach((i) => {
      if (i.batchType) {
        i.batchType = i.batchType.code;
      }

      if (!i.product || Object.keys(i.product).length === 0) {
        i.product = undefined;
      }

      if(i.expirationDate) {
        i.expirationDate = new Date(i.expirationDate);
      }
    });
  } else {
    entity.items = [];
  }

  return entity;
};
