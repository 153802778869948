<template>
  <div class="discounts">
    <div class="discounts__header">
      <div>Click to add or remove Alpine rewards to this order.</div>
      <div>Points: {{ appliedPoints }}<strong>/{{ pointBalance }}</strong> - Remaining: {{ pointBalance - appliedPoints
        }}
      </div>
    </div>
    <div v-if="loyaltyDiscounts.length == 0">
      <div class="discounts__no_items">
        No discounts available
      </div>
    </div>
    <div class="discounts__container">
      <div class="discounts__grid" :class="{ blur: isBlurred }">
        <div class="discounts__item" v-for="disc in loyaltyDiscounts" :key="disc.id"
          @click="!isBlurred && (isSelected(disc) ? removeDiscount(disc) : addDiscount(disc))"
          :class="{ 'discounts__item--selected': isSelected(disc) }">
          <div class="discounts__item__title">{{ disc.pageTitle }}</div>
          <div class="discounts__item__details">
            <div class="discounts__item__stacked"><span v-if="disc.disableStacking">Cannot be stacked.</span></div>
            <div class="discounts__item__points">{{ disc.pointsDeduction }} points</div>
          </div>
        </div>
      </div>
    </div>
    <div class="discounts__actions">
      <BaseBtn @click="close" :label="'Close'" :colorType="3" />
    </div>
  </div>
</template>

<script>

import BaseBtn from "@/components/Base/Button/Button.vue";
import Constants from "@/resources/Constants";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LoyaltyDiscounts",
  data() {
    return {
      isBlurred: false,
      Constants: Constants,
      pointBalance: 0,
      loyaltyDiscounts: [],
      selectedDiscounts: []
    };
  },
  props: {
    customer: {
      type: Object,
      required: true,
    }
  },
  components: {
    BaseBtn,
  },
  created: function () {
    this.getAvailableDiscounts({
      positiveCallback: (result) => {
        this.loyaltyDiscounts = result?.discounts ?? [];
        this.pointBalance = result?.pointBalance ?? 0;
      },
      negativeCallback: (error) => {
        console.log(error);
      },
      body: { customer: this.customer, order: this.posOrder },
    });

    this.setSelectedDiscounts(this.posOrder)
  },
  computed: {
    ...mapGetters({
      getPosOrder: "orderModule/posOrder",
      currentStore: "storeModule/currentStore",
    }),
    posOrder() {
      return this.getPosOrder;
    },
    appliedPoints() {
      return this.selectedDiscounts?.reduce((acc, curr) => acc + (curr?.alpineDiscount?.pointsDeduction ?? 0), 0) ?? 0;
    },
  },
  methods: {
    ...mapActions({
      setDiscountsPopUp: "posModule/setDiscountsPopUp",
      applyDiscountToOrder: "orderModule/applyDiscountToOrder",
      removeDiscountFromOrder: "orderModule/removeDiscountFromOrder",
      getAvailableDiscounts: "loyaltyModule/getAvailableDiscounts",
      getCouponById: "couponModule/getCouponById",
      setMessage: "setMessage",
    }),
    isSelected(discount) {
      return this?.selectedDiscounts?.some(disc => discount.id === disc.id || discount.posDiscountID === disc.alpineDiscount?.couponId);
    },
    async addDiscount(discount) {
      const couponId = discount.posDiscountID
      const canAdd = this.validateAlpineDiscountsApplied(discount)
      if (!canAdd) return
      this.isBlurred = true;
      this.getCouponById({
        _id: couponId,
        positiveCallback: async (result) => {
          const couponDiscountType = (result?.discountPercentage && result?.discountPercentage != '0')? '%' : '$'
          const couponAmount = couponDiscountType == '%' ? result?.discountPercentage : result?.discountAmount
          const couponInfo = {
            amountDiscountedInDollars: Number(result?.discountAmount ?? 0),
            couponCode: result?.code,
            couponAmount,
            couponPercentage: result?.discountPercentage,
            couponDiscountType,
            isCannabisOnly: result?.isForCannabisOnly,
            couponName: result?.name,
            msoCouponCode: result?.msoCoupon?.code,
            discountId: result?._id?.toString(),
          }

          const alpineDiscount = { ...discount, couponId: result._id.toString() }
          const coupon = { alpineDiscount, ...result, type: 'alpine-discount', ...couponInfo };
          try {
              const order = await this.applyDiscountToOrder({
              discount: coupon,
              orderId: this.posOrder._id,
              order: this.posOrder,
            })
            this.setSelectedDiscounts(order)
            this.isBlurred = false;
          }
          catch (error) {
            this.isBlurred = false;
          }
        },
        negativeCallback: () => {
          this.isBlurred = false;
        }
      });
    },
    close() {
      this.setDiscountsPopUp(false);
    },
    async removeDiscount(discount) {
      const disc = this.posOrder.discountsApplied.find(disc => discount.id === disc.id || discount.posDiscountID === disc.alpineDiscount?.couponId);
      this.isBlurred = true;
      this.removeDiscountFromOrder({
        discountId: disc._id,
        orderId: this.posOrder._id,
        order: this.posOrder,
        positiveCallback: (order) => {
          this.setSelectedDiscounts(order)
          this.isBlurred = false;
        },
        negativeCallback: (order) => {
          this.isBlurred = false;
        },
      });
    },
    setSelectedDiscounts(order) {
      this.selectedDiscounts = order?.discountsApplied ?? [];
    },
    validateAlpineDiscountsApplied(discount) {
      const alpineDiscountsApplied = this.selectedDiscounts?.filter(d => d.type == 'alpine-discount')?.map(d => d.alpineDiscount) ?? []
      const totalAdded = alpineDiscountsApplied.reduce((acc, curr) => acc + (curr?.pointsDeduction ?? 0), 0)
      const pointsDeduction = discount.pointsDeduction ?? 0

      const disableStacking = discount.disableStacking == true
      const discountsAlreadyApplied = alpineDiscountsApplied.length > 0
      const selectedDiscountsHaveStackingDisabled = alpineDiscountsApplied.filter(d => d.disableStacking === true).length > 0

      if (disableStacking && discountsAlreadyApplied) {
        this.setMessage({
          type: 'error',
          title: 'Error',
          text: 'Reward does not allow stacking.',
        });
        return false;
      }

      if (selectedDiscountsHaveStackingDisabled) {
        this.setMessage({
          type: 'error',
          title: 'Error',
          text: 'Applied Rewards do not allow stacking.',
        });
        return false;
      }

      if ((totalAdded + pointsDeduction) > this.pointBalance) {
        this.setMessage({
          type: 'error',
          title: 'Error',
          text: `Cannot add reward as it will exceed customer point balance ${this.pointBalance} < ${totalAdded + pointsDeduction}`,
        });
        return false;
      }
      return true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./_loyaltyDiscounts.scss";
</style>
