import HttpService from "@/services/HttpService";
import axios from 'axios';
import Apis from "@/services/Apis";
import { splitRequests } from './helpers/index'
import aggregateList from './helpers/aggregateList'
import aggregateRecords from './helpers/aggregateRecords'
import fieldsToNotSum from "./helpers/fieldsToNotSum";

const state = {
  data: {
    total: {},
    list: {},
    grandTotals: {},
    records: []
  },
  totalRequests: 1,
  requestsCompleted: 0,
  isLoading: false,
  loadingStatus: {
    getSalesTransactions: false,
    getInventoryTransactions: false,
    getFinanceTransactions: false,
    getCustomerTransactions: false,
    getCouponTransactions: false,
    getEmployeeTransactions: false,
  },
  saleType: 'recreational'
};

const mutations = {
  clearData: (state) => {
    state.data = {
      total: {},
      list: {},
      grandTotals: {},
      records: []
    };
    state.totalRequests = 1;
    state.requestsCompleted = 0;
    state.isLoading = false;
  },
  setTotalRequests: (state, value) => {
    state.totalRequests = value;
  },
  incrementRequestsCompleted: (state) => {
    state.requestsCompleted += 1;
  },
  setIsLoading: (state, value) => {
    state.isLoading = value || false;
  },
  addToList: (state, value) => {
    const reportName = value.reportName;
    const list = value.list;
    const total = value.total;
    const grandTotals = value.grandTotals;
    const records = value.records;
    const others = value.others;

    state.data.list = aggregateList(JSON.parse(JSON.stringify(state.data.list)), list, others, reportName)

    if (records && records.length > 0) {
      state.data.records = aggregateRecords(records, state.data.records, others)
    }

    if (total) {
      for (const [key, value] of Object.entries(total)) {
        if (state.data.total[key]) {
          state.data.total[key] += value
        } else {
          state.data.total[key] = value;
        }
      }
    }

    if (grandTotals) {
      for (const [key, value] of Object.entries(grandTotals)) {
        if (state.data.grandTotals[key]) {
          state.data.grandTotals[key] += value
        } else {
          state.data.grandTotals[key] = value;
        }
      }
    }

  },
  // deprecated
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setSaleType: (state, payload) => {
    state.saleType = payload
  },
};

const getters = {
  // new
  isLoading: (state) => state.isLoading,
  percentageCompleted: (state) => {
    if (!state.totalRequests) return 100;
    return Math.min((state.requestsCompleted / state.totalRequests) * 100, 100);
  },
  getData: (state) => state.data,
  // old - deprecating
  loadingStatus: (state) => state.loadingStatus,
  salesTransactions: (state) => state.loadingStatus.getSalesTransactions,
  inventoryTransactions: (state) => state.loadingStatus.getInventoryTransactions,
  financeTransactions: (state) => state.loadingStatus.getFinanceTransactions,
  customerTransactions: (state) => state.loadingStatus.getCustomerTransactions,
  couponTransactions: (state) => state.loadingStatus.getCouponTransactions,
  employeeTransactions: (state) => state.loadingStatus.getEmployeeTransactions,
  saleType: (state) => state.saleType
};

const actions = {
  clearData: ({ commit }) => {
    commit('clearData')
  },
  generate: ({ commit, state, dispatch }, payload) => {
    commit('clearData')
    commit('setIsLoading', true);
    const reportName = payload.reportType;
    const apiUrl = `${Apis.backendBaseUrl}${Apis.reports.generate}`;
    const requests = splitRequests(apiUrl, payload)

    // check if at least one request is present;
    if (!requests || requests.length == 0) {
      return commit(
        "setMessage",
        { text: 'Error while generating the report. Please contact the DF support.', type: "error" },
        { root: true }
      );
    }
    commit('setTotalRequests', requests.length);
    let promises = requests.map(request => dispatch('makeRequest', { request, reportName }))
    try {
      Promise.all(promises).then((res) => {
        commit('setIsLoading', false);
      })
        .catch((err) => {
          console.log(err)
          commit('setIsLoading', false);
          commit(
            "setMessage",
            { text: 'Error while generating the report. Please contact the DF support.', type: "error" },
            { root: true }
          );
        })
    } catch (err) {
      commit('setIsLoading', false);
      commit(
        "setMessage",
        { text: 'Error while generating the report. Please contact the DF support.', type: "error" },
        { root: true }
      );
    }
  },
  makeRequest: ({ commit }, { request, reportName }) => {
    return axios.get(request)
      .then((res) => {
        commit('incrementRequestsCompleted');
        const data = res.data.body;
        let list = data.days;
        const total = data.total;
        const grandTotals = data.grandTotals;
        const records = data.records;

        // sometimes the recods is an object instead of an array.......
        // it's an object for the report Sales Profit Margins by Group
        if (records && !Array.isArray(records)) {
          list = records;
        }

        delete data.days;
        delete data.total;
        delete data.grandTotals;
        delete data.records;
        commit('addToList', {
          reportName,
          list,
          total,
          grandTotals,
          records,
          others: { ...data }
        });
      })
  },

  // these below will be deprecated probably. we should be using the methods above
  getSalesTransactions: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getSalesTransactions",
      status: true,
    });

    const apiUrl = `${Apis.backendBaseUrl}${Apis.reports.getSalesTransactions}`;
    let finalUrl = generateFinalUrl(apiUrl, payload);

    HttpService.get(
      finalUrl,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getSalesTransactions",
          status: false,
        });
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },

  getInventoryTransactions: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getInventoryTransactions",
      status: true,
    });

    const apiUrl = `${Apis.backendBaseUrl}${Apis.reports.getInventoryTransactions}`;
    let finalUrl = generateFinalUrl(apiUrl, payload);

    HttpService.get(
      finalUrl,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getInventoryTransactions",
          status: false,
        });
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getCustomerTransactions: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getCustomerTransactions",
      status: true,
    });

    const apiUrl = `${Apis.backendBaseUrl}${Apis.reports.getCustomerTransactions}`;
    let finalUrl = generateFinalUrl(apiUrl, payload);

    HttpService.get(
      finalUrl,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getCustomerTransactions",
          status: false,
        });
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getCouponTransactions: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getCouponTransactions",
      status: true,
    });

    const apiUrl = `${Apis.backendBaseUrl}${Apis.reports.getCouponTransactions}`;
    let finalUrl = generateFinalUrl(apiUrl, payload);

    HttpService.get(
      finalUrl,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getCouponTransactions",
          status: false,
        });
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },

  getEmployeeTransactions: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getEmployeeTransactions",
      status: true,
    });

    const apiUrl = `${Apis.backendBaseUrl}${Apis.reports.getEmployeeTransactions}`;
    let finalUrl = generateFinalUrl(apiUrl, payload);

    HttpService.get(
      finalUrl,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getEmployeeTransactions",
          status: false,
        });
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },

  getFinanceTransactions: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getFinanceTransactions",
      status: true,
    });

    const apiUrl = `${Apis.backendBaseUrl}${Apis.reports.getFinanceTransactions}`;
    let finalUrl = generateFinalUrl(apiUrl, payload);

    HttpService.get(
      finalUrl,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getFinanceTransactions",
          status: false,
        });
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setSaleType: ({ commit, state }, payload) => {
    commit('setSaleType', payload)
  }
};

function generateFinalUrl(apiUrl, payload) {
  let params = []
  if (payload.selectedPeriod && payload.selectedPeriod.from) {
    params.push(`startTime=${payload.selectedPeriod.from}`)
  }
  if (payload.selectedPeriod && payload.selectedPeriod.to) {
    params.push(`endTime=${payload.selectedPeriod.to}`)
  }
  if (payload.selectedReportFor) {
    params.push(`reportFor=${payload.selectedReportFor}`)
  }
  if (payload.selectedReportSaleType) {
    params.push(`reportSaleType=${payload.selectedReportSaleType}`)
  }
  if (payload.selectedReportItemLocation) {
    params.push(`reportItemLocation=${payload.selectedReportItemLocation}`)
  }
  if (payload.selectedReportItemType) {
    params.push(`reportItemType=${payload.selectedReportItemType}`)
  }
  if (payload.selectedReportMemoType) {
    params.push(`reportMemoType=${payload.selectedReportMemoType}`)
  }
  if (payload.selectedReportCustomerLoyalty) {
    params.push(`reportCustomerLoyalty=${payload.selectedReportCustomerLoyalty}`)
  }
  if (payload.selectedReportAllThis) {
    params.push(`reportAllThis=${payload.selectedReportAllThis}`)
  }
  if (payload.reportType) {
    params.push(`reportType=${payload.reportType}`)
  }
  if (payload.filterText) {
    params.push(`filterText=${payload.filterText}`)
  }
  // <------- these are a problem. there MUST be just 1 range which is the selected period above. need to remove
  if (payload.filterRange && payload.filterRange.from) {
    params.push(`filterRangeFrom=${payload.filterRange.from}`)
  }
  if (payload.filterRange && payload.filterRange.to) {
    params.push(`filterRangeTo=${payload.filterRange.to}`)
  }

  let query = params.length > 0 ? `?${params.join('&')}` : '';
  return query ? `${apiUrl}${query}` : apiUrl;
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
