<template>
  <div class="specialPricing-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader :pageHeader="pageHeader" :selectedEntity="selectedSpecialPricing" :links="[
        { name: 'home', label: 'Home' },
        { name: 'specialPricings', label: 'Special Pricing' },
        {
          name: 'specialPricingEditor',
          label: selectedSpecialPricing._id,
          query: $route.query,
        },
      ]" :isEdit="isEdit" :isCollapsed="isCollapsed" :entity="entity" @updateIsEdit="isEdit = $event"
        @toggle-edit="toggleEdit" @toggle-sections="forceToggle"
        @show-search-modal="() => (showSearchModal = !showSearchModal)" @menu-option-click="menuOptionClick" />
      <div class="section-base">
        <div class="padded elevated-0 bordered">
          <div class="two-columns-layout two-columns-layout--responsive">
            <div class="
                                                                  grid-two-column
                                                                  two-columns-layout--responsive-horizontal-space-10
                                                                ">
              <Input :inputData="getInput('saleType')" :class="getInput('saleType').class" :isEdit="isEdit"
                :model="selectedSpecialPricing.saleType" @updateModel="selectedSpecialPricing.saleType = $event" />
              <Input :inputData="getInput('isActive')" :isEdit="isEdit" :model="selectedSpecialPricing.isActive"
                @updateModel="selectedSpecialPricing.isActive = $event" />
              <Input :inputData="getInput('isPublishedToOnlineStore')" :isEdit="isEdit"
                :model="selectedSpecialPricing.isPublishedToOnlineStore"
                @updateModel="selectedSpecialPricing.isPublishedToOnlineStore = $event" />
              <Input :inputData="getInput('group')" :isEdit="isEdit" :model="selectedSpecialPricing.group"
                @updateModel="selectedSpecialPricing.group = $event" />
              <Input :inputData="getInput('supplier')" :isEdit="isEdit" :model="selectedSpecialPricing.supplier"
                @updateModel="selectedSpecialPricing.supplier = $event" />
              <Input :inputData="getInput('brand')" :isEdit="isEdit" :model="selectedSpecialPricing.brand"
                @updateModel="selectedSpecialPricing.brand = $event" />
              <Input :inputData="getInput('size')" :isEdit="isEdit" :model="selectedSpecialPricing.size"
                @updateModel="selectedSpecialPricing.size = $event" />
              <Input :inputData="getInput('dayOfWeek')" :isEdit="isEdit" :model="selectedSpecialPricing.dayOfWeek"
                @updateModel="selectedSpecialPricing.dayOfWeek = $event" />

              <Input :inputData="getInput('quantityLimit')" :isEdit="isEdit" :model="selectedSpecialPricing.quantityLimit"
                @updateModel="selectedSpecialPricing.quantityLimit = $event" />
              <Input :inputData="getInput('expiringIn')" :isEdit="isEdit" :model="selectedSpecialPricing.expiringIn"
                @updateModel="selectedSpecialPricing.expiringIn = $event" />
              <Input :inputData="getInput('purchasedAfter')" :isEdit="isEdit" :model="selectedSpecialPricing.purchasedAfter"
                @updateModel="selectedSpecialPricing.purchasedAfter = $event" />

              <div class="grid-row-two-column special-pricing-type">
                <Input :inputData="getInput('type')" class="special-pricing-type__selection"
                  :class="getInput('type').class" :isEdit="isEdit" :model="selectedSpecialPricing.type"
                  @updateModel="selectedSpecialPricing.type = $event" />
                <Input v-show="isDollarDiscount" :inputData="getInput('price')" :isEdit="isEdit"
                  :model="selectedSpecialPricing.price" @updateModel="selectedSpecialPricing.price = $event" />
                <!-- <Input v-show="isDollarDiscount" :inputData="getInput('pricePreTax')" :isEdit="isEdit"
                  :model="selectedSpecialPricing.pricePreTax"
                  @updateModel="selectedSpecialPricing.pricePreTax = $event" /> -->
                <Input :inputData="getInput('quantity')" :isEdit="isEdit"
                  :model="selectedSpecialPricing.quantity" @updateModel="selectedSpecialPricing.quantity = $event" />
                <Input v-show="isPercentageDiscount" :inputData="getInput('percentageAmount')" :isEdit="isEdit"
                  :model="selectedSpecialPricing.percentageAmount"
                  @updateModel="selectedSpecialPricing.percentageAmount = $event" />
              </div>

              <Input :inputData="getInput('minimumPrice')" :isEdit="isEdit" :model="selectedSpecialPricing.minimumPrice"
                @updateModel="selectedSpecialPricing.minimumPrice = $event" />
              <Input :inputData="getInput('maximumPrice')" :isEdit="isEdit" :model="selectedSpecialPricing.maximumPrice"
                @updateModel="selectedSpecialPricing.maximumPrice = $event" />
              <Input :inputData="getInput('applyDateRange')" :class="getInput('applyDateRange').class" :isEdit="isEdit"
                :model="selectedSpecialPricing.applyDateRange"
                @updateModel="selectedSpecialPricing.applyDateRange = $event" />
              <Input :inputData="getInput('startDate')" :isEdit="isEdit" :model="selectedSpecialPricing.startDate"
                @updateModel="selectedSpecialPricing.startDate = $event" />
              <Input :inputData="getInput('endDate')" :isEdit="isEdit" :model="selectedSpecialPricing.endDate"
                @updateModel="selectedSpecialPricing.endDate = $event" />
            </div>
          </div>
        </div>
      </div>
      <EditorSection :title="`About ${selectedSpecialPricing.name || ''}`" sectionType="about"
        v-if="selectedSpecialPricing._id !== 'NEW'" :bus="bus">
        <About :entity="selectedSpecialPricing" />
      </EditorSection>
    </div>
    <SearchModal v-if="showSearchModal" :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)" :entity="entity" :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders" />
  </div>
</template>

<script>
import _ from "lodash";

import PageHeader from "@/components/PageHeader.vue";
import About from "@/components/About/About.vue";
import Input from "@/components/Input/Input.vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import router from "@/router";
import Constants from "@/resources/Constants";
import EditorSection from "@/components/EditorSection";
import Entities from "@/resources/Entities";
import Vue from "vue";
import { setNewEntity } from "@/utils/initialize-page";
import { validateFields } from "@/utils/validation/special-pricing";

import {
  specialPricingInputs,
  columnHeaders,
  filterCriteria,
  pageHeader,
} from "./inputs";

export default {
  name: "specialPricingEditor",
  data() {
    return {
      Constants: Constants,
      specialPricingInputs,
      isCollapsed: true,
      isEdit: false,
      showSearchModal: false,
      columnHeaders,
      filterCriteria,
      entity: Entities.SPECIALPRICING,
      bus: new Vue(),
      pageHeader,
      validationErrors: {},
    };
  },
  components: {
    PageHeader,
    About,
    Input,
    EditorSection,
  },
  mounted: function () {
    this.setPageTitle("Special Pricing");
    if (this.$route.query.id) {
      this.getSpecialPricingById({
        _id: this.$route.query.id,
      });
    } else {
      // new element
      setNewEntity(this.entity);
      this.isEdit = true;
    }
    this.getGroups();
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getSpecialPricingById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      loadingStatus: "productModule/loadingStatus",
      specialPricing: "productModule/specialPricing",
      groups: "groupModule/groups",
    }),
    isDollarDiscount() {
      return this.selectedSpecialPricing.type == "DOLLAR" || !this.selectedSpecialPricing.type
    },
    isPercentageDiscount() {
      return this.selectedSpecialPricing.type == "PERCENTAGE"
    },
    selectedSpecialPricing: {
      get() {
        return this.prefillEntityBeforeRendering(this.specialPricing);
      },
      set(entity) {
        return entity;
      },
    },
  },
  methods: {
    ...mapActions({
      getSpecialPricingById: "productModule/getSpecialPricingById",
      saveSpecialPricing: "productModule/saveSpecialPricing",
      deleteSpecialPricing: "productModule/deleteSpecialPricing",
      getGroups: "groupModule/getGroups",
      setMessagePopUp: "setMessagePopUp",
      getAdjacent: "productModule/getAdjacentSpecialPricing",
    }),
    getInput(field) {
      return specialPricingInputs.find((i) => i.id == field);
    },
    getValue(field) {
      return _.get(this.selectedSpecialPricing, field);
    },
    setValue(field, value) {
      _.set(this.selectedSpecialPricing, field, value);
      validateFields(this.selectedSpecialPricing, field, this.validationErrors);
    },
    isValid(field) {
      return _.get(this.validationErrors, field);
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    handleAdjacent: async function (mode) {
      this.getAdjacent({
        createdAt: this.selectedSpecialPricing.createdAt,
        mode,
        positiveCallback: (adjacent) => {
          if (adjacent) this.selectedSpecialPricing = adjacent;
        },
      });
    },
    selectItemFromSearch: function (entity, item) {
      this.selectedSpecialPricing[entity] = item;
    },
    toggleEdit() {
      if (!(this.selectedSpecialPricing._id === "NEW"))
        this.isEdit = !this.isEdit;
      if (this.selectedSpecialPricing._id === "NEW") {
        this.isEdit = true;
      }
    },
    menuOptionClick(entity, key) {
      if (key === "special-pricing-listing") {
        window.open(`/reports/inventory/inventoryListSpecialPricing`);
      }
    },
    clearItemFromSearch: function (entity) {
      this.selectedSpecialPricing[entity] = null;
    },
    prefillEntityBeforeRendering(entity) {
      if (entity.startDate && entity.startDate == "-") {
        entity.startDate = null;
      }

      if (entity.endDate && entity.endDate == "-") {
        entity.endDate = null;
      }
      return entity;
    },
  },
};
</script>

<style lang="scss" scoped>
.special-pricing-type {
  border: 1pt dashed $dark-accent-color;
  padding: 1rem;
  border-radius: .5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  &__selection {
    grid-column: 1/3;
  }
}
</style>