export const validateEntityBeforeSaving = (entity, options) => {
  let validationResult = {
    succeeded: true,
    message: "",
    isWarning: false,
  };

  if (entity.imageNormal?.secure_url && /^data:image\/[a-zA-Z]+;base64,/.test(entity.imageNormal.secure_url)) {
    validationResult.message =
      "Invalid Product Image Url: Base64 encoded images are not supported. Please upload an image file or enter a valid URL.";
    validationResult.succeeded = false;
    return validationResult;
  }

  return validationResult;
};
